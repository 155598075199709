import React, { useCallback, useState } from 'react';

import {
  Container,
  FloatingLabel,
  InfoIcon,
  InfoIconWrapper,
  InputWrapper,
  Modal,
} from './styles';

import { InputFields, InputInfo, InputProps } from './types';

export const defaultInputProps = {
  error: '',
  label: '',
  onBlur: null,
};

/** An input component wrapper for interacting with redux-form.  */
const Input = (props: InputProps) => {
  const {
    autoCapitalize,
    blurOnSubmit,
    textInputRef,
    info,
    input: {
      onBlur = () => {},
      onChange = () => {},
      onChangeText = () => {},
      ...inputProps
    },
    ...remainingProps
  } = props;

  const [isShowingInfo, setIsShowingInfo] = useState(false);
  const toggleInfo = useCallback(() => {
    setIsShowingInfo(!isShowingInfo);
  }, [isShowingInfo]);

  return (
    <Container style={props.containerStyle}>
      <InputWrapper
        {...inputProps}
        {...remainingProps}
        containerStyle={{ borderRadius: 8 }}
        autoCapitalize={autoCapitalize || 'none'}
        blurOnSubmit={typeof blurOnSubmit === 'undefined' ? true : blurOnSubmit}
        errorMessage={props.error}
        label={<FloatingLabel>{props.label}</FloatingLabel>}
        onBlur={onBlur}
        onChangeText={onChange as any}
        passwordRules=""
        readOnly={props.disabled}
        ref={textInputRef}
        testID={inputProps.name}
      />

      {info && (
        <InfoIconWrapper onPress={toggleInfo}>
          <InfoIcon size={16} />
        </InfoIconWrapper>
      )}

      {info && (
        <Modal
          isVisible={isShowingInfo}
          onClose={toggleInfo}
          title={info.title}
        >
          {info.content}
        </Modal>
      )}
    </Container>
  );
};

Input.defaultProps = defaultInputProps;

export default Input;
