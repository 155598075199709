/**
 * @tag ai-assistance
 * This file was written with the assistance of Github Copilot.
 */
import { ENVIRONMENT } from 'app/util/constants';
import {
  ValidationFunction,
  ValidationFactoryFunction,
  FormatValidationFactoryFunction,
  MinLengthValidationFactoryFunction,
  ConfirmValidationFactoryFunction,
} from './types';

/**
 * List of invalid area codes for US phone numbers.
 *
 * @tag ai-assistance
 * @see https://www.nanpa.com/ for guidelines and standards for US phone numbers.
 */
const AREA_CODE_ERRORS = {
  toll_free: ['800', '833', '844', '855', '866', '877', '888'],
  premium_rate: ['900', '976'],
  special_service: [
    '211',
    '311',
    '411',
    '511',
    '611',
    '711',
    '811',
    '911',
    '988',
  ],
  reserved: ['555'],
};

const ALLOWED_TEST_NUMBERS = ENVIRONMENT === 'production' ? [] : ['1231231234'];

/**
 * Regular expression to validate US phone numbers.
 * This pattern supports optional country code, area code with or without parentheses,
 * and various separators (hyphen, dot, or space).
 *
 * @tag ai-assistance
 * @see https://www.nanpa.com/ for guidelines and standards for US phone numbers.
 */
const VALID_PHONE_REGEX =
  /^(?:\+?1[-.\s]?)?\(?([2-9][0-8][0-9])\)?[-.\s]?([2-9][0-9]{2})[-.\s]?([0-9]{4})$/;

/**
 * Checks if a value is present.
 * @param message - The error message to return if the value is not present.
 * @returns A validation function.
 */
export const isPresent: ValidationFactoryFunction =
  (message: string = 'is required'): ValidationFunction =>
  (value: string) =>
    value && value.length ? false : message;

/**
 * Checks if a value matches a given format.
 * @param format - The regex format to test against.
 * @param message - The error message to return if the value does not match the format.
 * @returns A validation function.
 */
export const isFormat: FormatValidationFactoryFunction =
  (format: RegExp, message: string = 'is not valid'): ValidationFunction =>
  (value: string) =>
    format.test(value || '') ? false : message;

/**
 * Checks if a value meets a minimum length.
 * @param length - The minimum length required.
 * @param message - The error message to return if the value is too short.
 * @returns A validation function.
 */
export const isMinLength: MinLengthValidationFactoryFunction =
  (
    length: number,
    message: string = `Please use ${length} or more characters`
  ): ValidationFunction =>
  (value: string) =>
    value && value.length >= length ? false : message;

/**
 * Checks if a value matches a confirmation field.
 * @param confirmWith - The field to confirm with.
 * @param message - The error message to return if the values do not match.
 * @returns A validation function.
 */
export const isConfirmed: ConfirmValidationFactoryFunction =
  (
    confirmWith: string,
    message: string = "Passwords don't match"
  ): ValidationFunction =>
  (value: string, values: Record<string, string>) =>
    value === values[confirmWith] ? false : message;

/**
 * Checks if a value is a valid SSN format.
 * @param message - The error message to return if the value is not a valid SSN.
 * @returns A validation function.
 */
export const isSsnFormat: ValidationFactoryFunction =
  (message: string = 'is not valid'): ValidationFunction =>
  (value: string) => {
    const re =
      /^(?!000|666)[0-8][0-9]{2}[-]?(?!00)[0-9]{2}[-]?(?!0000)[0-9]{4}$/;
    return re.test(String(value).toLowerCase()) ? false : message;
  };

/**
 * Checks if a value is a valid phone number.
 * @param message - The error message to return if the value is not a valid phone number.
 * @returns A validation function.
 * @tag ai-assistance
 * @see https://www.nanpa.com/ for guidelines and standards for US phone numbers.
 */
export const isPhoneNumberValid: ValidationFactoryFunction =
  (message: string = 'is not valid'): ValidationFunction =>
  (value: string) => {
    const numbers = value?.replace(/[^\d]/g, '');

    if (ALLOWED_TEST_NUMBERS.includes(numbers)) return false;
    if (!VALID_PHONE_REGEX.test(numbers)) return message;
    if (invalidAreaCode(numbers.slice(0, 3))) return message;
    if (isSpecificAreaCodeInvalid(numbers.slice(0, 3))) return message;

    return false;
  };

/**
 * Checks if the area code is invalid.
 * @param areaCode - The area code to validate.
 * @returns True if the area code is invalid, otherwise false.
 * @tag ai-assistance
 */
const invalidAreaCode = (areaCode: string): boolean => {
  return areaCode.startsWith('0') || areaCode.startsWith('1');
};

/**
 * Checks if the area code is in the list of specific invalid area codes.
 * @param areaCode - The area code to validate.
 * @returns True if the area code is in the list of specific invalid area codes, otherwise false.
 * @tag ai-assistance
 */
const isSpecificAreaCodeInvalid = (areaCode: string): boolean => {
  for (const codes of Object.values(AREA_CODE_ERRORS)) {
    if (codes.includes(areaCode)) return true;
  }

  return false;
};
