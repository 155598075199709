import theme from 'app/util/theme';
import React from 'react';
import { StyleSheet } from 'react-native';

import Input from 'app/components/Common/Input';

/**
 * A text area component wrapper for interacting with redux-form.
 */
export const TextArea = (props) => {
  return (
    <Input
      {...props}
      input={{ ...props.input, multiline: true }}
      inputStyle={StyleSheet.flatten([styles.textArea, props.inputStyle])}
    />
  );
};

TextArea.defaultProps = Input.defaultProps;
TextArea.propTypes = Input.propTypes;

const styles = StyleSheet.create({
  textArea: {
    paddingBottom: theme.spacing / 4,
    marginTop: theme.spacing,
    minHeight: theme.spacing * 4,
  },
});

export default TextArea;
