import {
  LiveChatModalStyles as Styled,
  Platform,
} from 'app/components/LiveChatModal/styles';

export const LiveChatModal = (props) => {
  return (
    <Styled.Modal {...props}>
      <Styled.KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 80 : 0}
      >
        <Styled.InnerContainer>
          <Styled.Messages />
        </Styled.InnerContainer>
      </Styled.KeyboardAvoidingView>
    </Styled.Modal>
  );
};
