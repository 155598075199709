import { NavigationMode, PersonalInformationFormState } from './types';

import { isFormat, isPhoneNumberValid, isPresent } from 'app/util/validations';

import theme from 'app/util/theme';

export const defaultPersonalInformation: PersonalInformationFormState = {
  interpreterRequired: false,
  language: 'english',
  phoneNumber: '',
  preferredName: '',
  voicemailsAllowed: false,
};

export const defaultIcon = {
  color: theme.colors.default,
  name: 'user',
  type: 'font-awesome',
};

export const languageOptions = [
  { label: 'English', value: 'english' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'Not Listed', value: 'not_listed' },
  { label: 'Decline to Answer', value: 'decline' },
];

export const LINKS = [
  {
    path: '/my-settings/personal-information',
    title: 'Personal Information',
    subtitle: 'Edit your personal information.',
  },
  {
    path: '/my-settings/account-settings',
    title: 'Account Settings',
    subtitle: 'Change your email and password.',
  },
];

export { NavigationMode };

export const errorMessages = {
  language: {
    missing: 'Please specify your spoken language',
  },
  phoneNumber: {
    invalid: 'Please enter a 10-digit Phone Number',
    missing: 'Please enter your Phone Number',
    unsupported: 'Please enter a valid personal Phone Number',
  },
};

export const personalInformationValidations = {
  language: [isPresent(errorMessages.language.missing)],
  phoneNumber: [
    isPresent(errorMessages.phoneNumber.missing),
    isFormat(/^\(\d{3}\) \d{3} \d{4}$/, errorMessages.phoneNumber.invalid),
    isPhoneNumberValid(errorMessages.phoneNumber.unsupported),
  ],
};

export { Routes } from 'app/util/routes';

export { TestID } from 'app/util/test-id';

export { theme };
