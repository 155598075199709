import {
  AccountSettingsFormState,
  PersonalInformationFormState,
  User,
} from './types';

/**
 * Returns an object with properties of a `AccountSettingsFormState`
 * destructured from the current user object in the session.
 */
export const getAccountSettingsFormValuesFromUser = (user: User) => {
  const { email } = user;

  const formValues: AccountSettingsFormState = { email };

  Object.keys(formValues).forEach((field) => {
    if (typeof formValues[field] === 'undefined') delete formValues[field];
  });

  return formValues;
};

/**
 * Return an object with properties of a `PersonalInformationFormState`
 * destructured from the current user object in the session.
 */
export const getPersonalInformationFormValuesFromUser = (user: User) => {
  const {
    eligiblePatient: { phoneNumber: patientPhoneNumber },
    profile: {
      interpreterRequired,
      language,
      preferredName,
      voicemailsAllowed,
      phoneNumber: profilePhoneNumber,
    },
  } = user;

  const formValues: PersonalInformationFormState = {
    interpreterRequired,
    language,
    phoneNumber: profilePhoneNumber || patientPhoneNumber,
    preferredName,
    voicemailsAllowed,
  };

  Object.keys(formValues).forEach((field) => {
    if (typeof formValues[field] === 'undefined') delete formValues[field];
  });

  return formValues;
};

export { getProfileProgress } from 'app/util/profileUtils';

export const getUserName = (user) =>
  user.profile?.preferredName || `${user.firstName} ${user.lastName}`;

export { isEmpty } from 'app/util/methods';

export { launchCamera, launchImageLibrary } from 'app/actions/uiActions';

export { logEvent } from 'app/util/analytics';

export { showLocalNotification } from 'app/actions/uiActions';

export { updateUserDetails } from 'app/actions/userActions';
