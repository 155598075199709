/**
 * @tag ai-assistance
 * This file was written with the assistance of Github Copilot.
 */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Animated, PanResponder, StyleSheet, View } from 'react-native';
import { Button, Icon, Text } from 'react-native-elements';

import { ResponsivePressable } from 'app/components/Common/ResponsivePressable';
import { useNativeDriver } from 'app/util/constants';
import { playSound } from 'app/util/soundUtils';
import { TestID } from 'app/util/test-id';
import theme from 'app/util/theme';

import { ToastProps, ToastAction } from './types';

/**
 * Default properties for the Toast component
 * @tag ai-assistance
 */
export const defaultToastProps: ToastProps = {
  title: 'Test',
  count: 1,
  index: 0,
  onDismiss: () => {},
  onPress: () => {},
  actions: [],
  body: '',
  dismissIn: null,
  icon: '',
  status: 'info',
};

/**
 * Toast component to display notifications
 * @param {ToastProps} props - Properties for the Toast component
 * @returns {JSX.Element} The rendered Toast component
 * @tag ai-assistance
 */
const Toast = ({
  title = defaultToastProps.title,
  count = defaultToastProps.count,
  index = defaultToastProps.index,
  onDismiss = defaultToastProps.onDismiss,
  onPress = defaultToastProps.onPress,
  actions = defaultToastProps.actions,
  body = defaultToastProps.body,
  dismissIn = defaultToastProps.dismissIn,
  icon = defaultToastProps.icon,
  status = defaultToastProps.status,
}: ToastProps) => {
  const fade = useRef(new Animated.Value(1)).current;
  const pan = useRef(new Animated.ValueXY()).current;
  const slide = useRef(new Animated.Value(89)).current;
  const dismissTimeout = useRef<NodeJS.Timeout | null>(null);

  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: (evt, { dx }) => dx >= 2,
    onPanResponderGrant: () => {
      if (dismissTimeout.current) clearTimeout(dismissTimeout.current);
    },
    onPanResponderMove: (_, { dx }) => {
      if (dx) pan.setValue({ x: dx, y: 0 });
    },
    onPanResponderRelease: (_, { dx }) => {
      if (dx > 160) {
        springOutOfView();
      } else {
        springBackToInitialLocation();
        autoDismiss();
      }
    },
  });

  useEffect(() => {
    autoDismiss();
    slideUp();
    chime();
    return () => {
      if (dismissTimeout.current) clearTimeout(dismissTimeout.current);
    };
  }, []);

  useEffect(() => {
    if (count > index) slideUp();
    if (count < index) slideDown();
  }, [count]);

  const autoDismiss = useCallback(() => {
    if (dismissIn) {
      dismissTimeout.current = setTimeout(fadeOut, dismissIn);
    }
  }, [dismissIn]);

  const chime = useCallback(() => {
    playSound(status);
  }, [status]);

  const fadeOut = useCallback(() => {
    Animated.timing(fade, {
      toValue: 0,
      useNativeDriver,
    }).start(onDismiss);
  }, [fade, onDismiss]);

  const slideDown = useCallback(() => {
    slide.setValue(-89);
    Animated.spring(slide, {
      toValue: 0,
      friction: 5,
      useNativeDriver,
    }).start();
  }, [slide]);

  const slideUp = useCallback(() => {
    slide.setValue(89);
    Animated.spring(slide, {
      toValue: 0,
      friction: 5,
      useNativeDriver,
    }).start();
  }, [slide]);

  const springOutOfView = useCallback(() => {
    Animated.spring(pan, {
      friction: 5,
      toValue: { x: 2000, y: 0 },
      useNativeDriver,
    }).start(onDismiss);
  }, [pan, onDismiss]);

  const springBackToInitialLocation = useCallback(() => {
    Animated.spring(pan, {
      toValue: 0,
      friction: 5,
      useNativeDriver,
    }).start();
  }, [pan]);

  const fadeStyle = { opacity: fade };
  const slideStyle = { translateY: slide };
  const panStyle = {
    transform: [...pan.getTranslateTransform(), slideStyle],
  };

  const textColor =
    status === 'warning' ? theme.colors.bodyText : theme.colors.white;

  return (
    <Animated.View
      {...panResponder.panHandlers}
      style={[fadeStyle, panStyle, styles.toast, styles[`${status}Toast`]]}
      testID={`${TestID.Toast.Component}--${index}`}
    >
      <ResponsivePressable
        onPress={onPress}
        style={{
          alignItems: actions.length > 0 ? 'stretch' : 'center',
          justifyContent: 'space-between',
          flexDirection: actions.length > 0 ? 'column' : 'row',
        }}
        pressableStyle={{
          flex: 1,
          flexDirection: 'row',
        }}
        testID={TestID.Toast.Pressable}
      >
        <View style={styles.toastContent}>
          <View style={styles.titleRow}>
            {icon ? (
              <Icon
                color={textColor}
                type="font-awesome"
                containerStyle={styles.icon}
                name={icon}
              />
            ) : null}
            <Text
              style={{
                fontSize: body ? theme.fontSizes.body3 : theme.fontSizes.body2,
                color: textColor,
              }}
            >
              {title}
            </Text>
          </View>

          {body ? (
            <Text
              style={{
                fontSize: theme.fontSizes.body2,
                color: textColor,
              }}
            >
              {body}
            </Text>
          ) : null}
        </View>

        {actions.length > 0 ? (
          <View style={styles.actionWrapper}>
            {actions.map(({ iconName, ...action }: ToastAction) => (
              <Button
                icon={
                  iconName && (
                    <Icon color={textColor} size={40} name={iconName} />
                  )
                }
                key={action.title}
                testID={TestID.Toast.ActionButton}
                titleStyle={{ color: textColor }}
                {...action}
                onPress={() => {
                  action.onPress();
                  onDismiss();
                }}
              />
            ))}
          </View>
        ) : (
          <Button
            type="clear"
            icon={<Icon color={textColor} size={40} name="close" />}
            onPress={onDismiss}
            testID={TestID.Toast.DismissButton}
          />
        )}
      </ResponsivePressable>
    </Animated.View>
  );
};

export default Toast;

const styles = StyleSheet.create({
  toast: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderRadius: theme.border.radiusTen,
    padding: theme.spacing / 2,
    pointerEvents: 'auto',
    marginLeft: theme.spacing / 2,
    marginRight: theme.spacing / 2,
    marginTop: theme.spacing / 2,
    maxWidth: theme.breakpoints.small - theme.spacing,
  },
  toastPressable: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
  toastContent: {
    flex: 1,
  },
  infoToast: {},
  warningToast: {
    backgroundColor: theme.colors.warning,
  },
  errorToast: {
    backgroundColor: theme.colors.error,
  },
  successToast: {
    backgroundColor: theme.colors.success,
  },
  titleRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing / 4,
  },
  title: {
    color: '#fff',
  },
  icon: {
    marginRight: theme.spacing / 4,
  },
  actionWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing / 4,
  },
});
