import styled, { css } from 'styled-components/native';

import { KeyboardAvoidingView } from 'react-native';
import { default as Messages } from 'app/components/Messages';
import { Modal } from 'app/components/Common/Modal';
import { Platform } from 'react-native';
import { View } from 'app/components/Common/Styled';

export const LiveChatModalStyles = {
  InnerContainer: styled(View)`
    ${({ theme }) => css`
      flex: 1;
      margin-left: ${theme.spacing / 2}px;
      margin-right: ${theme.spacing / 2}px;
    `}
  `,

  KeyboardAvoidingView: styled(KeyboardAvoidingView)`
    flex: 1;
  `,

  Messages,

  Modal: styled(Modal).attrs(() => {
    return {
      fullScreen: true,
      contentStyleOverrides: {
        flex: 1,
        width: '100%',
        height: '100%',
      },
    };
  })``,
};

export { Platform };
