import styled, { css } from 'styled-components/native';
import { DefaultTheme } from 'types/theme';

import {
  AnchorProps,
  AttachmentProps,
  AuthorshipDependentView,
  HeaderUserStatusProps,
  SendButtonProps,
  MessageTextProps,
  MessageTextWrapperProps,
} from './types';

import Anchor from 'app/components/Common/Anchor';
import Autolink from 'react-native-autolink';
import { Button } from 'react-native-elements';
import { DotIndicator } from 'app/components/Common/svg-components';
import FilePreview from 'app/components/Common/FilePreview';
import { FlatList } from 'react-native';
import { Icon } from 'react-native-elements';
import * as Linking from 'expo-linking';
import { NotificationGroupAvatar } from './styles-for-avatars';
import { Platform } from 'react-native';
import { ResponsivePressable } from 'app/components/Common/ResponsivePressable';
import { RoundedAvatar } from './styles-for-avatars.js';
import { ScrollView } from 'app/components/Common/Styled';
import { Text } from 'react-native-elements';
import { TextArea } from 'app/components/Common/TextArea';
import TrackableButton from 'app/components/Common/TrackableButton';
import { View } from 'app/components/Common/Styled';

const getBackgroundColorForHeaderUserStatus = (status, theme) => {
  if (status === 'online') return theme.colors.brightGreen;
  if (status === 'idle') return theme.colors.yellow;

  return theme.colors.mediumGrey;
};

/** The size of the message actions that this component hides behind. */
const getFooterHeight = (theme) =>
  Platform.select({
    default: theme.fontSizes.body3 * 11.5,
    web: theme.fontSizes.body3 * 12.5,
  });

const getSendButtonStyle = (theme: DefaultTheme) => ({
  paddingBottom: theme.spacing / 2,
  paddingLeft: theme.spacing * 2,
  paddingRight: theme.spacing * 2,
  paddingTop: theme.spacing / 2,
  marginLeft: theme.spacing,
  marginRight: theme.isNarrow ? 0 : theme.spacing,
});

const getSendButtonContainerStyle = (
  onlyNarrow: boolean,
  onlyWide: boolean,
  theme: DefaultTheme
) => ({
  justifyContent: 'center',
  display:
    (onlyNarrow && !theme.isNarrow) || (onlyWide && theme.isNarrow)
      ? 'none'
      : 'initial',
});

const RowWrapper = styled(View)`
  ${({ theme }) => css`
    align-self: flex-start;
    align-items: center;
    flex-direction: row;
    margin-right: ${theme.spacing * 0.75}px;
  `}
`;

const SendButton = styled(Button).attrs(
  ({ onlyNarrow, onlyWide, onPress, theme }: SendButtonProps) => ({
    backgroundColor: onPress ? '#1166FF' : '#93a5c7',
    borderRadius: 4,
    buttonStyle: getSendButtonStyle(theme),
    containerStyle: getSendButtonContainerStyle(onlyNarrow, onlyWide, theme),
    fontFamily: theme.fontFamilyBold,
    fontSize: theme.fontSizes.body2,
  })
)<SendButtonProps>``;

const Timestamp = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.mediumGrey};
    font-size: ${theme.fontSizes.small}px;
    padding-top: ${theme.spacing / theme.spacing}px;
  `}
`;

const MessageContainer = styled(View)`
  ${({ theme }) => css`
    border-radius: ${theme.spacing / 2}px;
    flex: 1 0 auto;
    flex-wrap: wrap;
    margin-bottom: ${theme.spacing / 2}px;
    max-width: ${theme.isNarrow
      ? `${theme.windowDimensions.width - 32 - theme.spacing * 1.25}px`
      : `${theme.breakpoints.small}px`};
    padding: ${theme.spacing / 2}px;
  `}
`;

// non-UI support components

export { Linking };
export { Platform };

// exported for usage in `types.ts` (e.g., `typeof ScrollView`)

export { ScrollView };

// aggregated style objects

export const ActionButtonsStyles = {
  Button: ResponsivePressable,

  Icon: styled(Icon).attrs(({ theme }) => ({
    color: theme.colors.primary,
    containerStyle: {
      marginLeft: theme.spacing / 4,
    },
    size: theme.spacing * 2,
  }))``,

  LeftSide: styled(View)`
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
    justify-content: flex-start;
  `,

  PhoneIcon: styled(Icon).attrs(({ theme }) => ({
    name: 'phone',
    color: theme.colors.primary,
    size: theme.spacing,
  }))`
    ${({ theme }) => css`
      margin-right: ${theme.spacing * 0.75}px;
    `}
  `,

  RightSide: styled(View)`
    align-items: center;
    flex-direction: row;
  `,

  ScheduleCallButton: styled(TrackableButton).attrs({
    type: 'outline',
    containerStyle: {
      alignItems: 'baseline',
    },
    buttonStyle: {
      paddingVertical: 7, // NOTE: offset 1px width of outline
      paddingLeft: 20,
      paddingRight: 32,
    },
  })``,

  SendButton,

  Wrapper: styled(View)`
    ${({ theme }) => css`
      align-items: center;
      background-color: ${theme.colors.sand};
      flex-direction: row;
      flex-shrink: 0;
      justify-content: space-between;
      margin-bottom: ${theme.spacing}px;
    `}
  `,
};

export const AttachmentsStyles = {
  FilePreview: {
    Icon: styled(Icon).attrs(({ theme }) => ({
      containerStyle: {
        position: 'absolute',
        right: theme.spacing * -1,
        top: theme.spacing * -1,
      },
      color: 'red',
      raised: true,
      size: theme.spacing * 1.125,
    }))``,

    Wrapper: styled(FilePreview).attrs(({ theme }) => ({
      containerStyle: {
        margin: theme.spacing * 0.75,
      },
    }))``,
  },

  ScrollView: styled(ScrollView).attrs({
    contentContainerStyle: {
      justifyContent: 'center',
      minWidth: '100%',
    },
    horizontal: true,
  })``,

  Wrapper: styled(View)`
    ${({ theme }) => css`
      background-color: rgba(255, 255, 255, 0.8);
      border-width: 1px;
      border-color: ${theme.colors.placeholderText};
      bottom: ${getFooterHeight(theme)}px;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
    `}
  `,
};

export const EmptyStateStyles = {
  ProgressIndicator: DotIndicator,

  Text: styled(Text)`
    ${({ theme }) => css`
      color: ${theme.colors.mediumGrey};
      margin-top: ${theme.spacing * 1.25}px;
      text-align: center;
    `}
  `,

  Wrapper: styled(View)`
    align-items: center;
  `,
};

export const FooterStyles = {
  HolidayMessage: {
    Text: styled(Text)`
      ${({ theme }) => css`
        color: ${theme.colors.lightOrange};
        font-size: ${theme.fontSizes.body2}px;
      `}
    `,

    Wrapper: styled(View)`
      ${({ theme }) => css`
        background-color: ${theme.colors.darkOrange};
        border-radius: ${theme.spacing / 2}px;
        margin-top: ${theme.spacing}px;
        padding: ${theme.spacing}px;
      `}
    `,
  },

  Wrapper: styled(View)`
    ${({ theme }) => css`
      background-color: ${theme.colors.sand};
      flex-direction: column;
    `}
  `,
};

export const HeaderStyles = {
  Content: styled(View)`
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
  `,

  Title: {
    Text: styled(Text)`
      ${({ theme }) => css`
        color: ${theme.colors.bodyText}
        font-size: ${theme.fontSizes.body2}px;
        font-family: ${theme.fontFamilyBold};
        margin-bottom: ${theme.spacing / 2}px;
      `}
    `,

    Wrapper: RowWrapper,
  },

  Wrapper: styled(View).attrs(({ theme }) => ({
    ...Platform.select({
      // Render using fixed position on web
      web: {
        left: 0,
        position: 'fixed',
        right: 0,
        top: theme.navigationHeight - 2,
      },
    }),
  }))`
    ${({ theme }) => css`
      background-color: ${theme.colors.sand};
      height: ${theme.spacing * 5}px;
      padding-bottom: ${theme.spacing / 4}px;
      padding-left: ${theme.spacing / 2}px;
      padding-right: ${theme.spacing * 1.25}px;
      padding-top: ${theme.spacing / 2}px;
      width: 100%;
      z-index: ${theme.zIndexes.base};
    `}
  `,
};

export const InputFieldStyles = {
  Field: styled(TextArea).attrs({
    containerStyle: {
      borderRadius: 8,
      flex: 1,
    },
    inputStyle: {
      margin: 0,
      padding: 0,
      minHeight: 0,
    },
  })`
    ${({ theme }) => css`
      font-size: ${theme.fontSizes.body2}px;
      margin: 0px;
      padding: 0px;
    `}
  `,

  SendButton,

  Wrapper: styled(View)`
    ${({ theme }) => css`
      align-items: center;
      flex-direction: row;
      justify-content: center;
      padding-top: ${theme.spacing * 1.25}px;
      padding-bottom: ${theme.spacing * 1.75}px;
    `}
  `,
};

export const LoadMoreButtonStyles = {
  Button: styled(Button).attrs(({ theme }) => ({
    buttonStyle: { paddingHorizontal: theme.spacing * 1.25 },
    type: 'outline',
  }))``,

  Wrapper: styled(View)`
    ${({ theme }) => css`
      align-items: center;
      margin-bottom: ${theme.spacing * 1.25}px;
    `}
  `,
};

export const MessageBodyStyles = {
  Anchor: styled(Autolink).attrs<AnchorProps, any>(
    ({ patientIsAuthor, theme }) => ({
      linkStyle: {
        textDecorationLine: 'underline',
        ...(patientIsAuthor && {
          color: theme.colors.white,
          fontFamily: theme.fontFamilyMedium,
        }),
      },
    })
  )``,

  Text: styled(Text)<MessageTextProps>`
    ${({ patientIsAuthor, isRead, theme }) => css`
      flex-wrap: wrap;
      font-family: ${theme.fontFamilyBold};
      font-size: ${theme.fontSizes.body2}px;

      ${patientIsAuthor &&
      css`
        color: ${theme.colors.white};
      `}
      ${isRead &&
      css`
        font-family: ${theme.fontFamily};
      `}
    `}
  `,

  Wrapper: styled(MessageContainer)<MessageTextWrapperProps>`
    ${({ isFirst, isLast, isMiddle, patientIsAuthor, theme }) => css`
      align-items: flex-end;
      align-self: flex-start;
      background-color: ${theme.colors.lightGrey};

      ${isFirst &&
      patientIsAuthor &&
      css`
        border-bottom-right-radius: 0px;
      `}

      ${isLast &&
      patientIsAuthor &&
      css`
        border-top-right-radius: 0px;
      `}

    ${isMiddle &&
      patientIsAuthor &&
      css`
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
      `}


    ${isFirst &&
      !patientIsAuthor &&
      css`
        border-bottom-left-radius: 0px;
      `}

    ${isLast &&
      !patientIsAuthor &&
      css`
        border-top-left-radius: 0px;
      `}

    ${isMiddle &&
      !patientIsAuthor &&
      css`
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
      `}


    ${patientIsAuthor &&
      css`
        align-self: flex-end;
        background-color: ${theme.colors.mediumBlue};
      `}
    `}
  `,
};

export const MessageGroupStyles = {
  Author: {
    CallLink: Anchor,

    Icon: styled(Icon).attrs(({ theme }) => ({
      color: 'white',
      containerStyle: {
        marginRight: theme.spacing / 4,
      },
      name: 'call',
      size: theme.spacing * 1.125,
    }))`
      ${({ theme }) => css`
        background-color: ${theme.colors.primary};
        border-radius: ${theme.spacing / 4}px;
      `}
    `,

    Name: styled(Text)`
      ${({ theme }) => css`
        color: ${theme.colors.darkGrey};
        font-family: ${theme.fontFamilyMedium};
        font-size: ${theme.fontSizes.body2}px;
        margin-right: ${theme.spacing / 2}px;
      `}
    `,

    PhoneNumber: styled(View)`
      flex-direction: row;
    `,

    Wrapper: styled(View)`
      flex-direction: row;
    `,
  },

  Avatar: RoundedAvatar,

  Footer: styled(View)`
    ${({ theme }) => css`
      bottom: -${theme.spacing}px;
      position: absolute;
      right: -1px;
    `}
  `,

  Header: styled(View)<AuthorshipDependentView>`
    ${({ theme, patientIsAuthor }) => css`
      ${patientIsAuthor &&
      css`
        align-items: flex-end;
      `}
      flex: 1 1 auto;
      margin-bottom: ${theme.spacing / 3}px;
    `}
  `,

  DeliveryStatus: {
    Icon: styled(Icon).attrs(({ theme }) => ({
      color: theme.colors.mediumGrey,
      name: 'check',
      size: theme.spacing * 1.5,
      type: 'evilicon',
    }))``,
  },

  Content: styled(View)<AuthorshipDependentView>`
    ${({ patientIsAuthor }) => css`
      align-items: flex-start;
      flex: 1 0 auto;
      flex-direction: row;

      ${patientIsAuthor &&
      css`
        justify-content: flex-end;
      `}
    `}
  `,

  MessageGroupContentWrapper: styled(View)`
    flex: 1 0 auto;
  `,

  Timestamp,

  Wrapper: styled(View)<{
    patientIsAuthor?: boolean;
  }>`
    ${({ theme, patientIsAuthor }) => css`
      flex: 1 0 auto;
      flex-direction: row;
      margin-bottom: ${theme.spacing * 0.75}px;
      margin-left: ${theme.spacing}px;
      margin-right: ${theme.spacing * 5}px;

      ${patientIsAuthor &&
      css`
        align-items: flex-end;
        justify-content: flex-end;
        margin-left: ${theme.spacing * 5}px;
        margin-right: ${theme.spacing}px;
      `}
    `}
  `,
};

export const MessagesStyles = {
  Container: styled(View)`
    flex: 1;
  `,

  ListContainer: styled(View)`
    flex: 1;
    position: relative;
  `,

  MessagesList: styled(FlatList).attrs(({ theme }) => ({
    contentContainerStyle: {
      flexGrow: 1,
      paddingVertical: theme.spacing / 2,
    },
    keyboardShouldPersistTaps: 'handled',
    keyboardDismissMode: Platform.select({
      ios: 'interactive',
      android: 'on-drag',
    }),
  }))`
    ${({ theme }) => css`
      background-color: ${theme.colors.white};
      border-radius: ${theme.spacing / 2}px;
      flex: 1;
    `}
  `,
};

export const MessageStyles = {
  AttachmentPreview: styled(FilePreview).attrs<AttachmentProps, any>(
    ({ patientIsAuthor, theme }) => ({
      containerStyle: {
        alignItems: patientIsAuthor ? 'flex-end' : 'flex-start',
        alignSelf: patientIsAuthor ? 'flex-end' : 'flex-start',
        marginBottom: theme.spacing / 2,
      },
    })
  )`
    ${({ theme }) => css`
      icon-padding: ${theme.spacing * 2}px;
      max-height: ${theme.spacing * 12.5}px;
    `}
  `,

  Linking,

  Wrapper: styled(ResponsivePressable)`
    flex: 1 0 auto;
  `,
};

export const NotificationGroupStyles = {
  Avatar: NotificationGroupAvatar,

  Content: styled(View)`
    flex: 1;
  `,

  MessagesWrapper: View,

  Timestamp,

  Wrapper: styled(View)`
    ${({ theme }) => css`
      flex: 1 0 auto;
      flex-direction: row;
      margin-bottom: ${theme.spacing * 0.75}px;
      margin-left: ${theme.spacing}px;
      margin-right: ${theme.spacing * 5}px;
    `}
  `,
};

export const NotificationMessageStyles = {
  Text: styled(Text)`
    ${({ theme }) => css`
      color: ${theme.colors.white};
      font-size: ${theme.fontSizes.body1}px;
      max-width: 100%;
    `}
  `,

  Wrapper: styled(MessageContainer)`
    ${({ theme }) => css`
      color: ${theme.colors.white};
      background-color: ${theme.colors.badge};
    `}
  `,
};

export const ParticipantsStyles = {
  Wrapper: ScrollView,
};

export const ParticipantStyles = {
  Name: styled(Text)`
    ${({ theme }) => css`
      color: ${theme.colors.mediumGrey};
      font-size: ${theme.fontSizes.body2}px;
      margin-left: ${theme.spacing / 8}px;
      margin-right: ${theme.spacing / 8}px;
    `}
  `,

  Status: styled(View)<HeaderUserStatusProps>`
    ${({ status, theme }) => css`
      align-items: flex-start;
      background-color: ${theme.colors.mediumGrey};
      background-color: ${getBackgroundColorForHeaderUserStatus(status, theme)};
      border-radius: ${theme.spacing / 4}px;
      height: ${theme.spacing / 2}px;
      margin-right: ${theme.spacing / 4}px;
      width: ${theme.spacing / 2}px;
    `}
  `,

  Wrapper: RowWrapper,
};
