import React, { useEffect, useRef } from 'react';
import { Platform } from 'react-native';

import FormError from 'app/components/Common/FormError';
import { TestID } from 'app/util/test-id';

import { GOOGLE_API_KEY, selectClassName } from './constants';
import {
  fetchLocationFromAddress,
  formatGoogleAddress,
  formatValue,
  restoreVisibilityToInput,
  setGooglePlacesTextInputValue,
} from './helpers';
import {
  FixedHeightView,
  FloatingLabel,
  googlePlacesSelectStyles,
  googlePlacesStyles,
  GooglePlacesAutocomplete,
  InputWrapper,
} from './styles';
import { GooglePlacesInputProps } from './types';

/**
 * An input wrapper for redux-form that handles
 * autocomplete using the Google Places API.
 *
 * @example
 * import { Field } from 'redux-form';
 * import GooglePlacesInput from 'app/components/Common/GooglePlacesInput';
 *
 * const MyForm = () => (
 *   <View>
 *    <Field
 *      name="myAddressField"
 *      label="Enter your address"
 *      component={GooglePlacesInput}
 *    />
 *   </View>
 * );
 */
export const GooglePlacesInput = ({
  disabled = false,
  input: { onChange, value, name },
  height = 'auto',
  label = '',
  error,
}: GooglePlacesInputProps) => {
  const googlePlacesAutoCompleteRef = useRef();
  const formattedValue = formatValue(value);

  // Allow dynamic height when rendering in web.
  if (Platform.OS !== 'web') height = 'auto';

  /**
   * The native version of this component does not support
   * functioning as a controlled input, so this hook manually
   * updates the component's text value when this component's
   * props change.
   */
  useEffect(() => {
    setGooglePlacesTextInputValue(formattedValue, googlePlacesAutoCompleteRef);
  }, [formattedValue]);

  const onSelect = (detailsWeb, detailsNative) => {
    if (Platform.OS === 'web') {
      onSelectWeb(detailsWeb);
    } else {
      onSelectNative(detailsNative);
    }
  };

  const onSelectNative = (details) => {
    const address = formatGoogleAddress(details.address_components);
    const coords = details.geometry.location;
    onChange({ ...coords, address });
  };

  const onSelectWeb = async (details) => {
    const result = await fetchLocationFromAddress(details.label);
    onChange({ ...result });

    /**
     * This component attempts to conditionally render an <input />
     * when focused and blurred, but it behaves incorrectly in the
     * web when used as a controlled input and remains hidden after
     * selecting an option.
     *
     * The following function attempts to manually restore visibility
     * when a selection is made.
     */
    restoreVisibilityToInput(selectClassName);
  };

  return (
    <FixedHeightView
      height={height}
      testID={TestID.GooglePlacesInput.Component}
    >
      <InputWrapper disabled={disabled}>
        {label ? <FloatingLabel>{label}</FloatingLabel> : null}

        <GooglePlacesAutocomplete
          ref={googlePlacesAutoCompleteRef}
          testID={typeof name === 'string' ? name : ''}
          fetchDetails
          listViewDisplayed={false}
          nearbyPlacesAPI="GoogleReverseGeocoding"
          minLength={2}
          debounce={200}
          onPress={onSelect}
          disabled={disabled}
          selectProps={{
            className: selectClassName,
            inputProps: {
              autoComplete: 'off',
              autoCorrect: 'off',
              spellCheck: 'off',
              testID: TestID.GooglePlacesInput.TextInput,
              disabled,
            },
            inputValue: formattedValue,
            styles: googlePlacesSelectStyles(disabled),
            onChange: onSelect,
            onInputChange: (address, event) => {
              if (!event || event.action !== 'input-change') return;
              onChange({ address, lat: undefined, lng: undefined });
            },
          }}
          styles={googlePlacesStyles(disabled)}
          query={{
            key: GOOGLE_API_KEY,
            language: 'en',
            components: 'country:us', // Limit results to the US
          }}
          textInputProps={{
            enterKeyHint: 'search',
            testID: TestID.GooglePlacesInput.TextInput,
          }}
        />
      </InputWrapper>
      {error && <FormError>{error}</FormError>}
    </FixedHeightView>
  );
};

export default GooglePlacesInput;
