import PropTypes from 'prop-types';

const ProcedurePropTypes = PropTypes.shape({
  content: PropTypes.shape({
    confirmation: PropTypes.string,
    confirmationButtonText: PropTypes.string,
    description: PropTypes.string,
    details: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  images: PropTypes.shape({
    icon: PropTypes.string,
  }),
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  qualityMetrics: PropTypes.array,
  serviceType: PropTypes.string.isRequired,
});

export { ProcedurePropTypes };

const AddressPropTypes = {
  street: PropTypes.string,
  unit: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
};

const SurgeonPropTypes = {
  availability: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  hospitals: PropTypes.array,
  education: PropTypes.array,
  internships: PropTypes.array,
  residencies: PropTypes.array,
  profileImage: PropTypes.string,
};

export { AddressPropTypes };

const PatientPropTypes = {
  carrumId: PropTypes.number,
  clientId: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  address: PropTypes.shape(AddressPropTypes),
  name: PropTypes.string,
};

const EpisodePropTypes = {
  currentStep: PropTypes.shape({
    order: PropTypes.number,
  }),
  description: PropTypes.string,
  location: PropTypes.shape({
    address: PropTypes.shape(AddressPropTypes),
  }),
  physician: PropTypes.shape(SurgeonPropTypes),
  procedure: ProcedurePropTypes,
  provider: PropTypes.object,
  travel: PropTypes.shape({
    itinerary: PropTypes.string,
  }),
};

export { EpisodePropTypes };

const InquiryPropTypes = PropTypes.shape({
  description: PropTypes.string,
  location: PropTypes.object,
  procedure: PropTypes.object,
});

export { InquiryPropTypes };

const HistoryPropTypes = {
  goBack: PropTypes.func,
  push: PropTypes.func,
};

export { HistoryPropTypes };

const HospitalPropTypes = PropTypes.shape({
  name: PropTypes.string,
  images: PropTypes.shape({
    logo: PropTypes.string,
    logoIcon: PropTypes.string,
    mainImage: PropTypes.string,
  }),
});

export { HospitalPropTypes };

const SessionPropTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    eligiblePatient: PropTypes.shape(PatientPropTypes),
  }).isRequired,
};

export { SessionPropTypes };

export { SurgeonPropTypes };

const OopEstimatePropTypes = {
  coinsurance: PropTypes.number,
  deductible: PropTypes.number,
  savings: PropTypes.number,
  travel: PropTypes.number,
};

export { OopEstimatePropTypes };
