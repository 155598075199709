import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dimensions, Platform, StyleSheet, View } from 'react-native';

import theme, { zIndexes } from 'app/util/theme';

import Toast from './Toast';
import { ToastsProps } from './types';

/**
 * Renders an animated list of in-app notifications.
 */
const Toasts = ({ notifications, onDismiss, onPress }: ToastsProps) => {
  const [dimensions, setDimensions] = useState(Dimensions.get('window'));

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      'change',
      onDimensionsChange
    );
    return () => subscription?.remove();
  }, []);

  const onDimensionsChange = (event) => {
    setDimensions(event.window);
  };

  const { width } = dimensions;

  return (
    <View
      testID="Toasts"
      style={StyleSheet.flatten([
        styles.container,
        width > theme.breakpoints.xsmall ? styles.containerWide : {},
      ])}
    >
      {notifications.map((item, index) => (
        <Toast
          onDismiss={() => onDismiss(index)}
          onPress={() => onPress(item)}
          count={notifications.length}
          index={index}
          key={item.id}
          {...item}
        />
      ))}
    </View>
  );
};

Toasts.propTypes = {
  notifications: PropTypes.array.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onPress: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    left: 0,
    right: 0,
    bottom: 80,
    top: 0,
    paddingBottom: theme.spacing * 1.25,
    zIndex: zIndexes.overlay,
    overflow: 'visible',
    justifyContent: 'flex-end',
    pointerEvents: Platform.select({
      web: 'none',
      default: 'box-none',
    }),
    position: Platform.select({
      web: 'fixed',
      default: 'absolute',
    }),
  },

  containerWide: {
    bottom: 0,
    right: undefined,
  },
});

export default Toasts;
