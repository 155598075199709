import {
  breakpoints,
  headerAnimationConfig,
  TestID,
} from 'app/components/App/Navigation/constants';

import {
  useEffect,
  useRef,
  useSafeAreaInsets,
  useSession,
  useUi,
  useWindowDimensions,
} from 'app/components/App/Navigation/hooks';

import { NavigationStyles as Styled } from 'app/components/App/Navigation/styles';

import {
  Animated,
  CarrumLogo,
  ChatButton,
  ClientLogo,
  FeatureSwitch,
  MenuIcon,
  NavigationLinks,
  NeedHelpCta,
  ProfileMenu,
} from './subcomponents';

/**
 * Navigation that toggles responsively to display at top or side
 * of the screen based on screen width.
 */
export const Navigation = () => {
  const slide = useRef(new Animated.Value(-100)).current;
  const hasTopNavLinks = useWindowDimensions().width > breakpoints.small;
  const { profileMenuOpen: profileMenuIsOpen } = useUi();
  const { coreToken: isLoggedIn } = useSession();
  const { top } = useSafeAreaInsets();

  useEffect(() => {
    Animated.timing(slide, { ...headerAnimationConfig, toValue: 0 }).start();
  }, []);

  return (
    <Styled.HeaderAnimation
      // TODO: handle animation in SC - https://carrumhealth.atlassian.net/browse/TEC-1648
      style={{ transform: [{ translateY: slide }] }}
      testID={TestID.Navigation.Component}
    >
      <Styled.Header topInset={top}>
        <Styled.Containers.Left>
          <CarrumLogo />

          <ClientLogo />
        </Styled.Containers.Left>

        <Styled.Containers.Center>
          {hasTopNavLinks && <NavigationLinks />}
        </Styled.Containers.Center>

        <Styled.Containers.Right>
          <FeatureSwitch name="pre-episode-messaging" state="off">
            <NeedHelpCta />
          </FeatureSwitch>

          <FeatureSwitch name="pre-episode-messaging" state="on">
            {/* include this component only when logged in */}
            {isLoggedIn && <ChatButton />}
          </FeatureSwitch>

          {hasTopNavLinks ? (
            <ProfileMenu isOpen={profileMenuIsOpen} />
          ) : (
            <MenuIcon />
          )}
        </Styled.Containers.Right>
      </Styled.Header>
    </Styled.HeaderAnimation>
  );
};

export default Navigation;
