import React from 'react';
import { StyleSheet } from 'react-native';

import { ButtonProps, IconProps } from 'react-native-elements';
import {
  Link,
  LinkProps,
  useHistory,
} from '@cross-platform/react-router-native';

import theme from 'app/util/theme';
import { TestID } from 'app/util/test-id';

import { Button } from './styles';

const { primary } = theme.colors;

/**
 * `icon` is defined as `IconNode` in RRDom & RRNative. However, we pass a string instead.
 * This omit allows us to "override" (remove and then add) the `icon` prop as a string further below.
 */
type OmittedButtonProps = Omit<ButtonProps, 'icon' | 'style'>;

interface AnchorWithIconProps extends OmittedButtonProps, LinkProps {
  icon?: string;
  iconColor?: IconProps['color'];
  iconSize?: IconProps['size'];
  iconStyle?: IconProps['style'];
}

/**
 * Renders clickable text with an icon that opens a new route.
 *
 * @example
 * <AnchorWithIcon icon="open-in-new" title="My anchor" to="/some-path" />
 */
export const AnchorWithIcon = ({
  buttonStyle = {},
  containerStyle = {},
  disabled = false,
  icon,
  iconColor = primary,
  iconSize = 26,
  iconStyle = {},
  onPress,
  title = '',
  titleStyle = {},
  to = '#',
  ...rest
}: AnchorWithIconProps) => {
  const history = useHistory();

  const defaultTitleStyle = {
    fontSize: theme.fontSizes.body1,
  };

  const mergedTitleStyle = StyleSheet.flatten([defaultTitleStyle, titleStyle]);

  return (
    <Link
      component={() => (
        <Button
          {...rest}
          buttonStyle={buttonStyle}
          containerStyle={containerStyle}
          disabled={disabled}
          icon={{
            containerStyle: iconStyle,
            color: disabled ? '#ccc' : iconColor,
            name: icon,
          }}
          onPress={!disabled && onPress ? onPress : () => history.push(to)}
          title={title}
          titleStyle={mergedTitleStyle}
          type="clear"
        />
      )}
    />
  );
};

export default AnchorWithIcon;
