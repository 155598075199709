import { Platform } from 'react-native';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import sha1 from 'crypto-js/sha1';
import * as Linking from 'expo-linking';
import { datadogRum } from '@cross-platform/datadog-rum';

import {
  GOOGLE_API_KEY,
  PATIENT_FAQ_URL,
  PATIENT_SILVER_FAQ_URL,
} from 'app/util/constants';

export function formatDateRailsToDisplay(str) {
  if (!str) {
    return;
  }
  const dateArr = str.split('-');
  return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
}

export function formatDateDisplayToRails(str) {
  if (!str) {
    return;
  }
  const dateArr = str.split(/\/|-/);
  return `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`;
}

export function decryptDigest(digest) {
  return CryptoJS.AES.decrypt(digest.toString(), 'employeeApp').toString(
    CryptoJS.enc.Utf8
  );
}

const dateForEligibility = (date) =>
  /^\d{2}-\d{2}-\d{4}$/.test(date) ? formatDateDisplayToRails(date) : date;

/**
 * TODO: Write docs
 */
export function generateEligibilityDigest(params, digest) {
  const salt = [
    params.firstName.toLowerCase(),
    params.lastName.toLowerCase(),
    dateForEligibility(params.dateOfBirth),
    params.employerRegistrationId,
  ].join('');

  return sha1(salt + decryptDigest(digest)).toString();
}

/**
 * Format an address object into a string.
 *
 * @param   {object}   address      address object
 * @param   {boolean}  withCountry  whether to include 'United States'
 *
 * @return  {string}                formatted address string
 */
export const toAddressString = (address) =>
  [
    address.unit ? `${address.street} Unit ${address.unit}` : address.street,
    address.city,
    `${address.state} ${address.postalCode}`,
  ]
    .filter((isPresent) => isPresent)
    .join(', ');

/**
 * Format a phone number string.
 *
 * @param   {string}  rawPhoneNumber  raw phone number string
 *
 * @return  {string}                  formatted phone number string
 */
export const formatPhoneNumber = (rawPhoneNumber) => {
  const strippedNumber = rawPhoneNumber.replace(/\D/g, '');
  if (strippedNumber?.length === 0) return '';
  if (strippedNumber?.length <= 3) return `(${strippedNumber.slice(0, 3)})`;
  if (strippedNumber?.length <= 6)
    return `(${strippedNumber.slice(0, 3)}) ${strippedNumber.slice(3, 6)}`;

  return `(${strippedNumber.slice(0, 3)}) ${strippedNumber.slice(
    3,
    6
  )} ${strippedNumber.slice(6, 10)}`;
};

/**
 * Take a given number and returns a formatted currency string in USD.
 *
 * @param   {integer}  number  number to format
 *
 * @return  {string}           formatted currency string
 */
export const toCurrencyString = (number) =>
  '$' +
  parseInt(number)
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})+)/g, '$1,');

/**
 * Append time and timezone offset to a date string to support `Date.parse()`.
 *
 * @example
 * toDateTimeString('01/02/2020'); // '01/02/2020 00:00:00';
 * toDateTimeString('01/02/2020', '08:00'); // '01/02/2020 08:00:00';
 */
export const toDateTimeString = (date, time = '00:00', offset = '00') =>
  `${date} ${time}:${offset}`;

/**
 * Filter through the address components from a Google API response and find
 * and object with a given type.
 *
 * @param   {array}   components  array of Google API address components
 * @param   {string}  type        component type to find
 *
 * @return  {object}              address component with the given type
 *
 * @example
 *
 *  axios.get('https://maps.googleapis.com/maps/api/geocode/json', {...}).then(resp => {
 *    const { address_components } = resp.data.results[0];
 *    console.log('street number', getAddressComponent(address_components, 'street_number'));
 *  });
 */
export const getAddressComponent = (components, type) =>
  components.filter(({ types }) => types.indexOf(type) !== -1)[0] || {};

/**
 * Parse an array of Google API address components and return formatted object.
 *
 * @param   {array}   components  array of Google API address components
 *
 * @return  {object}              formatted address object
 *
 * @example
 *
 *  axios.get('https://maps.googleapis.com/maps/api/geocode/json', {...}).then(resp => {
 *    const { address_components } = resp.data.results[0];
 *    console.log('address', formatGoogleAddress(address_components));
 *  });
 */
export const formatGoogleAddress = (components) => ({
  street: [
    getAddressComponent(components, 'street_number').short_name,
    getAddressComponent(components, 'route').short_name,
  ].join(' '),
  unit: (
    getAddressComponent(components, 'subpremise').short_name || ''
  ).toUpperCase(),
  // NOTE: The Google Maps API will generally return an address component
  //       named `locality` or `administrative_area_level_3` that represents
  //       the city name. This is documented in the following web page:
  //
  //       https://developers.google.com/maps/documentation/javascript/supported_types
  //
  //       In some cases, the city name is more ambiguous and neither of the
  //       above values are returned. This seems to happen in densely-populated
  //       areas, such as New York City. In these cases, we fall back to the
  //       `sublocality` or `neighborhood` components, which seems to match the
  //       results in the official Google Maps web application.
  //
  //       For more background and discussion, see the following Google issue:
  //       https://issuetracker.google.com/issues/35820656
  city:
    getAddressComponent(components, 'locality').long_name ||
    getAddressComponent(components, 'administrative_area_level_3').long_name ||
    getAddressComponent(components, 'sublocality').long_name ||
    getAddressComponent(components, 'neighborhood').long_name,
  state: getAddressComponent(components, 'administrative_area_level_1')
    .short_name,
  postalCode: getAddressComponent(components, 'postal_code').short_name,
});

/**
 * Make a GET request to Google's Geocode API to fetch a canonical
 * representation of an address.
 *
 * @param   {string|object}  address  string or address object
 *
 * @return  {object}                  formatted address object
 */
export const fetchLocationFromAddress = async (address) => {
  address = typeof address === 'string' ? address : toAddressString(address);

  const response = await axios.get(
    'https://maps.googleapis.com/maps/api/geocode/json',
    {
      params: { key: GOOGLE_API_KEY, address },
    }
  );

  let result = response?.data?.results && response?.data?.results[0];

  if (response?.data?.error_message) {
    logError(response.data.error_message, { address });
  }

  if (
    response?.data?.geometry?.location &&
    response?.data?.geometry.location.lat === 0 &&
    response?.data?.geometry.location.lng === 0
  ) {
    logError('got [0,0] as lat/lng', { address, response });
    result = null;
  }

  if (!result) return;

  return {
    address: formatGoogleAddress(result.address_components),
    lat: result.geometry.location.lat,
    lng: result.geometry.location.lng,
  };
};

/**
 * Make a GET request to Google's Location API to fetch the distance between
 * two locations.
 *
 * @param   {string}  origin   lat/lng of the origin
 * @param   {string}  address  lat/lng of the destination
 *
 * @return  {object}           details about the distance between locations
 */
export const fetchDistanceFromAddress = async (origin, destination) => {
  let result;

  if (Platform.OS === 'web') {
    result = await fetchDistanceFromAddressWeb(origin, destination);
  } else {
    result = await fetchDistanceFromAddressNative(origin, destination);
  }

  return result;
};

export const fetchDistanceFromAddressNative = async (origin, destination) => {
  const response = await axios.get(
    'https://maps.googleapis.com/maps/api/directions/json',
    {
      params: {
        origin,
        destination,
        key: GOOGLE_API_KEY,
      },
    }
  );

  if (!response.data || response.data.routes.length === 0) return;
  const { distance } = response.data.routes[0].legs[0];

  return distance;
};

export const fetchDistanceFromAddressWeb = (origin, destination) => {
  return new Promise((resolve, reject) => {
    /* eslint-disable no-undef */
    const DirectionsService = new google.maps.DirectionsService();

    DirectionsService.route(
      {
        origin: new google.maps.LatLng(
          origin.split(',')[0],
          origin.split(',')[1]
        ),
        destination: new google.maps.LatLng(
          destination.split(',')[0],
          destination.split(',')[1]
        ),
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === 'OK') {
          resolve(response?.routes?.[0]?.legs?.[0]?.distance);
        } else {
          reject(
            `Unable to fetch distance due to Google Maps API error: ${status}`
          );
        }
      }
    );
    /* eslint-enable no-undef */
  });
};

/**
 * Return true if a given filename has an image extension.
 *
 * @param   {string}  filename  filename to parse
 *
 * @return  {boolean}           whether the given filename is an image
 */
export const isImage = (filename) =>
  /^data:image|\.(jpg|jpeg|png|gif)$/i.test(filename);

/**
 * Return `true` if a given filename has a video extension.
 *
 * @param   {string}   filename  filename to parse
 *
 * @return  {boolean}            whether given filename is a video
 */
export const isVideo = (filename) =>
  /^data:video|\.(mp4|m4a|ogg)$/i.test(filename);

/**
 * Return true if a given filename has an image or video extension.
 *
 * @param   {string}   filename  filename to parse
 *
 * @return  {boolean}            whether given filename is an image or video
 */
export const isMedia = (filename) => isImage(filename) || isVideo(filename);

/**
 * Return the page number from a pagination link url
 *
 * @param    {string}  url  pagination link from the JSON API response
 *
 * @return   {number}       page number in the link
 */
export const pageNumberFromUrl = (url) => {
  let queryParams;

  try {
    ({ queryParams } = Linking.parse(url));
  } catch (error) {
    return null;
  }

  return Number(queryParams['page[number]']);
};

/**
 * Replace non-alphanumeric characters with dashes and downcase the string.
 *
 * @param   {string}  string  string to parameterize
 *
 * @return  {string}          parameterized string
 */
export const parameterize = (string) =>
  string.toLowerCase().replace(/[^\w]/g, '-').replace(/-$/, '');

/**
 * Remove leading and trailing white spaces from any string values in a given
 * object.
 *
 * @param   {object}  source  object with string values to trim
 *
 * @return  {object}          object with trimmed string values
 */
export const trimWhitespace = (source) => {
  const result = {};

  Object.keys(source).forEach((key) => {
    result[key] = source[key]?.trim ? source[key].trim() : source[key];
  });

  return result;
};

/**
 * Log errors locally in the development environment or log them with Datadog
 * for other environments such as production and staging.
 *
 * @param  {object}  error  native error object
 * @param  {object}  data   additional details
 */
export function logError(error, data = undefined) {
  if (process.env.NODE_ENV === 'development') return console.error(error, data);

  try {
    captureDatadogError(error, data);
  } catch (e) {
    console.error('Unable to capture error for Datadog logs', e);
  }
}

/**
 * Accept error details using one of three supported function signatures.
 * Process the details into a standard object format, then post to Datadog.
 *
 * - Extract standard attributes when possible: the error message and stack
 *   trace object.
 * - Extract custom attributes that are commonly used and supported by the
 *   Datadog API, such as `cause` and `fileName`.
 * - Assign all remaining attributes to the custom `data` attribute.
 *
 * See reference links:
 *
 * - https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm
 * - https://docs.datadoghq.com/real_user_monitoring/error_tracking/mobile/reactnative/
 *
 * @param  {string|object}     error  error message text or error details for a
 *                                    standard exception
 * @param  {object|undefined}  data   error details for a standard or custom
 *                                    exception
 *
 * This method supports one of three function signatures.
 *
 * TODO: Refactor the various calls to `logError()` to use a single method signature and
 *       standard object structure to greatly simplify logic for posting to Datadog.
 *
 * 1. `logError(error)`
 * 2. `logError(str, customObj)`
 * 3. `logError(str, error)`
 *
 * @example for scenario 1
 *
 * try {
 *   throw new Error("Something went wrong");
 * } catch (error) {
 *   captureDatadogError(error);
 * }
 *
 * @example for scenario 2
 *
 * try {
 *   throw new Error("Something went wrong");
 * } catch (error) {
 *   captureDatadogError('scenario 2', { foo: 'foo', bar: 'bar'  });
 * }
 *
 * @example for scenario 3
 *
 * try {
 *   throw new Error("Something went wrong");
 * } catch (error) {
 *   captureDatadogError('scenario 3', error);
 * }
 */
const captureDatadogError = (error, data) => {
  try {
    if (Platform.OS === 'web') {
      captureDatadogErrorWeb(error, data);
    } else {
      captureDatadogErrorNative(error, data);
    }
  } catch {
    console.error('Error capture for Datadog logs failed.', {
      error,
      data,
    });
  }
};

const captureDatadogErrorNative = (error, data) => {
  const [message, stack, errorDetails] = getStandardDatadogErrorAttributes(
    error,
    data
  );

  const customAttributes = getCustomDatadogErrorAttributes(errorDetails);

  datadogRum.addError(message, 'CUSTOM', stack, { customAttributes });
};

const captureDatadogErrorWeb = (error, data) => {
  datadogRum.addError(error, data);
};

/**
 * Accept an error details object and return custom details formatted for
 * sending to Datadog.
 *
 * - If the key of an error detail property is included in a list of common
 *   custom attributes, assign the property to the output object and delete it.
 *
 * - After common attributes have been removed, consider the remaining error
 *   details to be custom data and assign them to `customAttributes.data`.
 *
 * @param  {object}  errorDetails  structured details about the error which may
 *                                 be a standard javascript or api exception
 *                                 that was caught, or some custom object
 *
 * @return                         data formatted for sending to Datadog
 */
const getCustomDatadogErrorAttributes = (errorDetails) => {
  if (typeof errorDetails !== 'object') return {}; // only process objects

  const customAttributes = {};

  const errorObjectKeys = [
    'name', // type of error (standard or custom)
    'cause', // original error which caused current error (for error chaining)
    'fileName',
    'lineNumber',
    'columnNumber',
  ];
  // NOTE: Standard values for `errorObjectKeys.name` include "Error",
  //       "TypeError", "ReferenceError", "SyntaxError", "RangeError",
  //       "EvalError", "URIError", and "DOMException".

  // include known custom attributes then delete
  errorObjectKeys.forEach((key) => {
    const errorKeyIsDefined = errorDetails[key];
    customAttributes[key] = errorKeyIsDefined ? errorDetails[key] : undefined;
    delete errorDetails[key];
  });

  // include remaining attributes as custom data
  customAttributes.data = Object.keys(errorDetails) ? errorDetails : {};

  return customAttributes;
};

/**
 * Iterate over error details to identify and structure standard attributes
 * to be posted to Datadog.
 *
 * The upstream `logError` function can be called with various signatures. This
 * method uses the same signature and maps data into a standard form.
 */
const getStandardDatadogErrorAttributes = (error, data) => {
  let message = '';
  let stack = {};
  let errorDetails = {};

  // map standard attributes differently based on first argument type
  if (typeof error === 'object') {
    // handle scenario 1
    errorDetails = { ...error };
    message = errorDetails.message ? errorDetails.message : '';
    stack = errorDetails.stack ? errorDetails.stack : {};
  }

  if (typeof error === 'string') {
    // handle scenarios 2 and 3
    errorDetails = { ...data };
    message = error;
    stack = errorDetails.stack ? errorDetails.stack : {};
  }

  // delete properties to avoid posting duplicating data downstream
  delete errorDetails.stack;
  delete errorDetails.message;

  return [message, stack, errorDetails];
};

export const isEmpty = (object) => !object || Object.keys(object).length === 0;

/**
 * Extract an object with the current values of a form of a given name.
 *
 * @param   {object}  form   form reducer object
 * @param   {string}  name   name of the form
 * @return  {object}         object with form values
 */
export const extractFormValues = (form, name) =>
  (form && form[name] && form[name].values) || {};

/**
 * Exclude a given message from being logged using `console.warn`.
 *
 * @param  {string}  warning  warning message to exclude from logs
 */
export const ignoreConsoleWarning = (warning) => {
  if (!console.warn) return;
  const _warn = console.warn;

  console.warn = (...args) => {
    if (args[0]?.includes(warning)) return;

    _warn(...args);
  };
};

/**
 * Check whether the current date is within a number of days of a given date.
 *
 * @param   {date}     originDate  date to compare to
 * @param   {number}   dayRange    number of days in comparison window
 * @return  {boolean}              whether we are within N days or not
 */
export const isWithinDateRangeOf = (originDate, dayRange) => {
  const dateSetAsDate = new Date(originDate).getTime();
  const dayRangeInMilliseconds = 1000 * 60 * 60 * 24 * dayRange;

  return new Date().getTime() < dateSetAsDate + dayRangeInMilliseconds;
};

export const getFaqUrl = (clientData) => {
  return clientData?.customContent?.faqUrl ?? PATIENT_FAQ_URL;
};

export const getSilverFaqUrl = (clientData) => {
  return clientData?.customContent?.faqUrl ?? PATIENT_SILVER_FAQ_URL;
};
