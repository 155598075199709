import theme from 'app/util/theme';
import { isFormat, isPresent, isPhoneNumberValid } from 'app/util/validations';

export const imageSize = theme.spacing * 16;

export { TestID } from 'app/util/test-id';

export const valuePropositionsColumnWidth = 320;

export const errorMessages = {
  dateOfBirth: {
    missing: 'Please select your birth month, day, and year.',
  },
  phoneNumber: {
    invalid: 'Please enter a 10-digit Phone Number',
    missing: 'Please enter your Phone Number',
    unsupported: 'Please enter a valid personal Phone Number',
  },
};

export const oneClickWelcomeValidations = {
  dateOfBirth: [isPresent(errorMessages.dateOfBirth.missing)],
  phoneNumber: [
    isPresent(errorMessages.phoneNumber.missing),
    isFormat(/^\(\d{3}\) \d{3} \d{4}$/, errorMessages.phoneNumber.invalid),
    isPhoneNumberValid(errorMessages.phoneNumber.unsupported),
  ],
};

export { theme };
